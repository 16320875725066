import { voiceType } from './../voice.service';
import { VoiceService } from '../voice.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';

@Component({
  selector: 'app-edit-voice-dialog',
  templateUrl: './edit-voice-dialog.component.html',
  styleUrls: [ './edit-voice-dialog.component.css' ]
})
export class EditVoiceDialogComponent implements OnInit {


  public volume: number;
  public pitch: number;
  public rate: number;
  public pause: number;
  public voice: SpeechSynthesisVoice;
  public countDown: number;

  public voices: SpeechSynthesisVoice[];

  voiceUttr: voiceType;
  constructor (public voiceUttrService: VoiceService,
    public dialogRef: MatDialogRef<EditVoiceDialogComponent>) {
    // console.log(this.voiceUttrService);
    // this.volume = this.voiceEdit.uttr.volume;
    // this.rate = this.voiceEdit.uttr.rate;
    // this.pitch = this.voiceEdit.uttr.pitch;
    // if (this.voiceEdit.uttr.voice != null) {
    //   this.voice = this.voiceEdit.uttr.voice;
    // }
  }


  ngOnInit() {
    // console.log("!!!!!!! initing !!!!!!!!!!");
    this.voiceUttrService.currentVoice.subscribe(voiceObj => {
      this.voiceUttr = voiceObj;
      this.volume = this.voiceUttr.volume;
      this.pitch = this.voiceUttr.pitch;
      this.voice = this.voiceUttr.voice;
      this.rate = this.voiceUttr.rate;
      this.pause = this.voiceUttr.pause;
      this.countDown = this.voiceUttr.countDown;
    });
  }
  ngAfterViewInit(): void {
    const s = this.setSpeech();
    s.then((voices: any) => {
      this.voices = voices;
      this.voices = this.voices.filter(elem => elem.lang.match('en-*'));

      // console.log(voices);
      if (this.voice == null) {
        this.voice = this.voices.filter(elem => elem.default)[ 0 ];
      }
    });
  }
  setSpeech() {
    return new Promise((resolve, reject) => {
      const synth = window.speechSynthesis;
      let id;
      id = setInterval(() => {
        if (synth.getVoices().length !== 0) {
          resolve(synth.getVoices());
          clearInterval(id);
        }
      }, 10);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  saveChanges() {
    this.voiceUttr.voice = this.voice;
    // console.log(this.voice);
    this.voiceUttr.volume = this.volume;
    // console.log(this.volume);

    this.voiceUttr.rate = this.rate;
    // console.log(this.rate);
    this.voiceUttr.pause = this.pause;
    this.voiceUttr.pitch = this.pitch;
    this.voiceUttr.countDown = this.countDown;
    // console.log(this.pitch);
    // console.log(this.voiceUttr);
    // console.log('before close **');
    // console.log(this.voices);
    // console.log("**");
    this.voiceUttrService.changeVoice(this.voiceUttr);
    this.dialogRef.close();
  }


}
