<mat-drawer-container class="container" autosize>
  <!-- <mat-drawer #drawer class="sidenav">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>¨
        </button>
        <span style="padding-left: 16px;">Spelling Test</span>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-drawer> -->
  <div class="sidebar-content">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <!-- <button mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button> -->
        <span style="padding-left: 16px;">Spelling Test</span>
        <span class="spacer"></span>
        <div *ngIf="auth.user | async as user; else showLogin">
          <span class="mat-h4" fxHide.lt-md>Hello {{ user.displayName }}!</span>
          <span class="mat-h4" fxHide.gt-md>Logged In!</span>
          <button mat-icon-button (click)="logout()" matTooltip="logout">
            <mat-icon>logout</mat-icon>
          </button>
        </div>
        <ng-template #showLogin>
          <button mat-icon-button (click)="signIn()" matTooltip="sign in and save the words in the cloud">
            <mat-icon>person</mat-icon>
          </button>
        </ng-template>


        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>graphic_eq</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editVoice()">Modify Voice</button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <app-web-speech-synthesis-demo></app-web-speech-synthesis-demo>
  </div>
</mat-drawer-container>
