<h1 mat-dialog-title>Edit voice</h1>
<div>
  <div class="option">
    <label for="volume">Volume</label>
    <mat-slider
      [thumbLabel]="true"
      [(ngModel)]="volume"
      type="range"
      min="0"
      [max]="2"
      step="0.1"
      name="volume"
      id="volume"
      value="1"
      color="primary"
    >
    </mat-slider>
  </div>
  <div class="option">
    <label for="pitch">Pitch</label>
    <mat-slider
      [thumbLabel]="true"
      [(ngModel)]="pitch"
      type="range"
      [min]="0"
      [max]="2"
      step="0.1"
      name="pitch"
      id="pitch"
      value="1"
      color="primary"
    >
    </mat-slider>
  </div>
  <div class="rate">
    <label for="rate">Rate</label>
    <mat-slider
      [thumbLabel]="true"
      [(ngModel)]="rate"
      type="range"
      [min]="0"
      [max]="2"
      [step]="0.1"
      name="rate"
      id="rate"
      color="primary"
    >
    </mat-slider>
  </div>
  <div>
    <mat-form-field>
      <input
        matInput
        type="number"
        min="100"
        max="20000"
        step="500"
        [(ngModel)]="pause"
        placeholder="word pause (in milliseconds)"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <input
        matInput
        type="number"
        min="100"
        max="20000"
        step="500"
        [(ngModel)]="countDown"
        placeholder="count down pause (in milliseconds)"
      />
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Select a voice </mat-label>

    <!-- <mat-select [(ngModel)]="voice" name="voice" [(value)]="voice"> -->
    <!-- <mat-select name="voice" [(value)]="voice"> -->
    <mat-select [(ngModel)]="voice" name="voice">
      <!-- <mat-option [value]="voice" selected>{{voice.name}}{{voice|json}}</mat-option> -->
      <mat-option *ngFor="let voiceElem of voices" [value]="voiceElem">
        {{ voiceElem.name }} ({{ voiceElem.lang }})
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button (click)="closeDialog()" mat-stroked-button color="secondary">
    discard
  </button>
  <button mat-flat-button (click)="saveChanges()" color="primary">save</button>
</div>
