import { WordObj } from "./../web-speech-synthesis-demo/web-speech-synthesis-demo.component";
import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-box",
  templateUrl: "./dialog-box.component.html",
  styleUrls: ["./dialog-box.component.css"]
})
export class DialogBoxComponent implements OnInit {

  action: string;
  localData: any;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { wordObj: WordObj, action: string }) {
    console.log(data);
    this.localData = { ...data.wordObj };
    this.action = this.data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.localData });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }

  ngOnInit() {
  }

}
