import { VoiceService } from './../voice.service';
import { Subject, observable, BehaviorSubject } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-upload-words-dialog',
  templateUrl: './upload-words-dialog.component.html',
  styleUrls: ['./upload-words-dialog.component.css']
})
export class UploadWordsDialogComponent implements OnInit {

  public fileString;

  words: string[] = [];
  constructor(public dialogRef: MatDialogRef<UploadWordsDialogComponent>, private snackBar: MatSnackBar) {
    this.fileString;
  }
  ngOnInit() {
  }



  add() {

    this.dialogRef.close(this.words);
  }
  change(event: Event) {
    this.readThis(event.target);
  }
  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();
    const fileNameArr = file.name.split('.');
    if (fileNameArr[fileNameArr.length - 1] !== 'json') {
      this.snackBar.open('invalid file type', null, {
        duration: 3000
      });
      return;
    }


    myReader.onloadend = (e) => {

      const strArr: any = myReader.result;
      const p = JSON.parse(strArr);
      // console.log('p = ' + p);
      for (let i = 0; i < p.length; i++) {
        const word = p[i];
        this.words.push(word);
      }
      // let x = (myReader.result);

      // console.log(x);
      // let y = JSON.stringify(myReader.result);
      // console.log(y);
    };
    myReader.readAsText(file);

  }

}

  // onFileChanged(event) {
  //   this.selectedFile = event.target.files[ 0 ];
  //   const fileReader = new FileReader();
  //   fileReader.readAsText(this.selectedFile, "UTF-8");
  //   // fileReader.onload = (x) => {
  //   //   // console.log(JSON.parse(x.target.result));
  //   // };
  //   fileReader.onloadend = (e) => {
  //     console.log(fileReader.result);
  //     console.log(typeof (fileReader.result));
  //     // this.words = fileReader.result;
  //   };
  //   fileReader.onerror = (error) => {
  //     console.log(error);
  // }
  // }

// }
