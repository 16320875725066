import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: [ './support-dialog.component.css' ]
})
export class SupportDialogComponent implements OnInit {

  constructor (@Inject(MAT_DIALOG_DATA) public data) { }
  ngOnInit() {
  }

}
