<h1 mat-dialog-title>add words from a file</h1>
<!-- <div>
  <input type="file" id="input" (change)="change($event)" />
</div> -->

<div class="file-input">
  <input type="file" id="file" class="file" (change)="change($event)">
  <label for="file">Select file</label>
</div>

<h6 style="color: darkred;">please use appropriate file</h6>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button type="submit" (click)="add()" cdkFocusInitial>
    add
  </button>
</mat-dialog-actions>
