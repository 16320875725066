<h1 mat-dialog-title>
  <strong>{{ action }}</strong>
</h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="action !== 'Delete'; else elseTemplate">
    <input placeholder="{{ action }} Word" matInput [(ngModel)]="localData.word" />
  </mat-form-field>
  <ng-template #elseTemplate>
    Sure to delete <b>{{ localData.word }}</b>?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()" [color]="action==='Delete'? 'warn': 'primary'">{{ action }}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button>Cancel</button>
</div>
