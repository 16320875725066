import { VoiceService } from "./voice.service";
import { EditVoiceDialogComponent } from "./edit-voice-dialog/edit-voice-dialog.component";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  public voices: SpeechSynthesisVoice[];
  utterance: SpeechSynthesisUtterance;
  items: Observable<any[]>;
  constructor(public dialog: MatDialog,
    public voiceEdit: VoiceService,
    public auth: AngularFireAuth) {
    // this.utterance = this.voiceEdit.currentVoice;
  }

  editVoice() {
    const dialogRef = this.dialog.open(EditVoiceDialogComponent, {
    });
  }
  signIn() {
    this.auth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());

  }
  logout() {
    this.auth.signOut();
    this.auth.currentUser = null;
  }
}

