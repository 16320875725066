import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "./material.module";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { WebSpeechSynthesisDemoComponent } from "./web-speech-synthesis-demo/web-speech-synthesis-demo.component";
import { DialogBoxComponent } from "./dialog-box/dialog-box.component";

import { SupportDialogComponent } from "./support-dialog/support-dialog.component";
import { EditVoiceDialogComponent } from "./edit-voice-dialog/edit-voice-dialog.component";
import { UploadWordsDialogComponent } from "./upload-words-dialog/upload-words-dialog.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { AngularFirestoreModule } from "@angular/fire/firestore";
@NgModule({
  declarations: [
    AppComponent,
    WebSpeechSynthesisDemoComponent,
    DialogBoxComponent,
    SupportDialogComponent,
    EditVoiceDialogComponent,
    UploadWordsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule.forRoot([
      // ADDING ROUTE WIHTOUT DELETING THE COMPONENT REFERENCE(in app.component.html) WOULD CAUSE FIREBASE TO CALL EVERYTHING TWICE!!!
      // { path: "", component: WebSpeechSynthesisDemoComponent }
      // DO NOT ADD ROUTE IF YOU DONT NEED IT!
    ]),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule
  ],
  entryComponents: [
    DialogBoxComponent,
    SupportDialogComponent,
    EditVoiceDialogComponent,
    UploadWordsDialogComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
