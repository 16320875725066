<div class="content" *ngIf="!isShowingTestContainer; else TestContainer">
  <div fxLayout="column" fxLayoutAlign.gt-sm="space-around stretch" fxLayout.gt-sm="row" fxLayoutGap="60px">
    <div fxLayout="column" fxFlex.gt-sm="20" fxFlex.gt-md="15" fxLayoutGap="15px">
      <div fxLayout="column" fxLayoutGap="15px">

        <button #stopButton (click)="startTheTest()" class="extended-fab-button" mat-fab color="primary">
          <mat-icon>play_arrow</mat-icon>
          <span class="extended-fab-button__text">Start The Test</span>
        </button>
        <div fxLayoutGap="15px" fxLayout="column" fxHide.lt-sm>
          <a #downloadButton mat-raised-button (click)="download()">
            download words
          </a>
          <button mat-stroked-button color="primary" (click)="upload()">
            upload words
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex.gt-sm="80">
      <div #tableContainer>
        <table mat-table [dataSource]="WordsWithIndexes" #mytable class="mat-elevation-z8">
          <ng-component matColumnDef="Position">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element;let i = index">{{i+1}}</td>
          </ng-component>
          <ng-container matColumnDef="word">
            <th mat-header-cell *matHeaderCellDef>Word</th>
            <td mat-cell *matCellDef="let element">{{ element.word }}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element; let i=index" class="action-link">
              <a (click)="openDialog('Update', element)">Edit</a><span class="span-column">&nbsp;|&nbsp;</span>
              <a (click)="openDialog('Delete', element)">Delete</a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <button (click)="openDialog('Add',null)" class="extended-fab-button fab-position" mat-fab color="accent">
      <mat-icon>add_circle</mat-icon>
      <span class="extended-fab-button__text">Add word</span>
    </button>


  </div>
</div>

<ng-template #TestContainer>
  <div fxLayout="column" fxLayoutGap="20px" class="test-container">
    <div #progressBarContainer style="margin-bottom: 10px;">
      <mat-progress-bar color="primary" mode="determined" [value]="progressBarValue">
      </mat-progress-bar>
    </div>
    <div>
      <!-- <button #stopButton (click)="stop()" mat-flat-button color="warn">
        <mat-icon>stop</mat-icon> stop the test
      </button> -->
      <button #stopButton (click)="stop()" class="extended-fab-button fab-position" mat-fab color="warn">
        <mat-icon>stop</mat-icon>
        <span class="extended-fab-button__text">stop the test</span>
      </button>
    </div>
  </div>
</ng-template>
