import { from, Observable, of } from "rxjs";
import { voiceType } from "./../voice.service";
import { VoiceService } from "../voice.service";
import { SupportDialogComponent } from "./../support-dialog/support-dialog.component";
import { DialogBoxComponent } from "./../dialog-box/dialog-box.component";
import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2 } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import { UploadWordsDialogComponent } from "../upload-words-dialog/upload-words-dialog.component";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { auditTime, delay, filter, map, take, throttleTime, } from "rxjs/operators";

export interface WordObj {
  word: string;
  index: number;
}
@Component({
  selector: "app-web-speech-synthesis-demo",
  templateUrl: "./web-speech-synthesis-demo.component.html",
  styleUrls: ["./web-speech-synthesis-demo.component.css"],
})
export class WebSpeechSynthesisDemoComponent implements OnInit {
  displayedColumns: string[] = ["Position", "word", "action"];
  WordsWithIndexes: WordObj[] = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild("stopButton") stopButton: MatButton;
  @ViewChild("progressBarContainer")
  progressBarContainer: ElementRef;
  @ViewChild("downloadButton") downloadButton: MatButton;
  isShowingTestContainer = false;
  unit1 = [
    "appearance",
    "leisure",
    "perceive",
    "volunteer",
    // 'euphoria',
    // 'irritable',
    // 'accustomed',
    // 'depression',
    // 'optimistic',
    // 'attitude',
    // 'intrigued',
    // 'economics',
    // 'commerce',
    // 'disorientation',
    // 'colleague',
    // 'commonplace',
    // 'phenomenon',
    // 'exponentially',
    // 'relevance',
    // 'expatriate',
    // 'peace',
    // 'capacity',
    // 'ethnicity',
    // 'communal',
    // 'accompanied',
    // 'immigrant',
    // 'refugee',
    // 'biracial',
    // 'diversity',
    // "measure",
  ];

  VoiceUttr: voiceType;
  timeTableInMs = 0;
  waitInMs = 3000;
  waitForNumbers = 1000;
  timeOutRefArr = [];
  timeOutRefFromTest: any;
  progressBarValue = 0;
  authValue;
  constructor(
    public WordDialog: MatDialog,
    public supportDialog: MatDialog,
    public editVoiceDialog: MatDialog,
    private voiceService: VoiceService,
    public renderer: Renderer2,
    public firestore: AngularFirestore,
    public auth: AngularFireAuth
  ) { }
  StartLoadFromCloud() {
    if (typeof this.authValue !== "undefined") {
      this.firestore.collection("users").doc(this.authValue.uid).get()
        .subscribe(
          document => {
            if (document.data() && JSON.stringify((document.data() as any).WordsWithIndexes) !== JSON.stringify(this.WordsWithIndexes)) {
              const documentData = document.data() as any;
              if (documentData && documentData.WordsWithIndexes &&
                JSON.stringify(documentData.WordsWithIndexes) !== JSON.stringify(this.WordsWithIndexes)) {
                const isFirstTwoItemsNotEqual =
                  JSON.stringify(this.WordsWithIndexes[0]) !== JSON.stringify(documentData.WordsWithIndexes[0])
                  && JSON.stringify(this.WordsWithIndexes[1]) !== JSON.stringify(documentData.WordsWithIndexes[1])
                if (isFirstTwoItemsNotEqual) {
                  documentData.WordsWithIndexes.forEach((wordObj: WordObj) => {
                    this.addRowData({ word: wordObj.word, index: this.WordsWithIndexes.length - 1 });
                  });
                }
              }
            }
          }, error => console.log("ERROR:", error),
          () => {
            this.updateWordsInTheCloud();
          }
        );
    }
  }

  updateWordsInTheCloud() {
    if (this.authValue && this.authValue !== null) {
      this.firestore.collection("users").doc(this.authValue.uid).set({
        Email: this.authValue.email,
        WordsWithIndexes: [...this.WordsWithIndexes]
      });
    }
  }

  updateWordsInLocalStorage() {
    localStorage.setItem("WordsWithIndexes", JSON.stringify(this.WordsWithIndexes));
  }

  getWordsFromLocalStorage(): WordObj[] {
    // console.log("GETTING FROM LOCAL", JSON.parse(localStorage.getItem("WordsWithIndexes")));
    return JSON.parse(localStorage.getItem("WordsWithIndexes")) ?? [];
  }

  ngOnInit() {
    if (!("speechSynthesis" in window)) {
      this.supportDialog.open(SupportDialogComponent, {
        height: "400px",
        width: "600px",
      });
    }
    this.voiceService.currentVoice.subscribe((voice) => {
      this.VoiceUttr = voice;
      this.waitInMs = voice.pause;
      this.waitForNumbers = voice.countDown;
    });

    this.WordsWithIndexes = [...this.getWordsFromLocalStorage()];

    this.auth.onAuthStateChanged((value) => {
      if (value) {
        this.authValue = value;
        this.StartLoadFromCloud();
      }
    });
  }

  openDialog(action, wordObj: WordObj) {
    // if (typeof this.WordsWithIndexes === "undefined") { this.WordsWithIndexes = []; }
    if (wordObj === null) { wordObj = { index: this.WordsWithIndexes.length, word: "" }; }
    // wordObj.index = wordObj.index !== null ? wordObj.index : this.WordsWithIndexes.length;
    // console.log("object:", wordObj);
    const dialogRef = this.WordDialog.open(DialogBoxComponent, {
      // width: '250px',
      data: { action, wordObj },
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          switch (result.event) {
            case "Add":
              this.addRowData(result.data);
              break;
            case "Update":
              this.updateRowData(result.data);
              break;
            case "Delete":
              this.deleteRowData(result.data);
              break;
          }
        }

      }
    );
  }
  addRowData(rowObj: WordObj) {
    this.WordsWithIndexes.push({
      index: this.WordsWithIndexes.length,
      word: rowObj.word,
    });
    // this.addWordToCloud();

    this.table.renderRows();
    this.updateWordsInTheCloud();
    this.updateWordsInLocalStorage();
  }
  updateRowData(rowObj: WordObj) {
    // console.log("updating...");
    this.WordsWithIndexes.find(i => i.index === rowObj.index).word = rowObj.word;
    this.updateWordsInTheCloud();
    this.updateWordsInLocalStorage();


  }
  deleteRowData(rowObj: WordObj) {
    this.WordsWithIndexes.splice(rowObj.index, 1);
    this.table.renderRows();
    this.updateWordsInTheCloud();
    this.updateWordsInLocalStorage();
  }
  startTheTest() {
    this.isShowingTestContainer = true;
    const synth = window.speechSynthesis;
    // const numbersToTell = [ 'five', 'four', 'three', 'two', 'one' ];
    const numbersToTell = ["three", "two", "one"];
    // console.log("starting test...");
    const words = this.WordsWithIndexes.map((x) => x.word);
    let utterPre = new SpeechSynthesisUtterance("The test will start in");
    utterPre = this.setUtterance(utterPre);
    synth.speak(utterPre);
    this.progressBarValue = 0;
    this.speak(numbersToTell, this.waitForNumbers);
    this.speak(words, this.waitInMs, true);

    this.timeOutRefFromTest = setTimeout(() => {
      this.testEnded(0);
    }, this.timeTableInMs);
  }

  speak(arr: Array<string>, waitTimeInMs, addInProgressBar = false) {
    const synth = window.speechSynthesis;
    for (let i = 0; i < arr.length; i++) {
      this.timeTableInMs += waitTimeInMs;
      const timeOut = setTimeout(() => {
        let uttr = new SpeechSynthesisUtterance();
        uttr = this.setUtterance(uttr);
        uttr.text = arr[i];
        synth.speak(uttr);
        if (addInProgressBar) {
          this.progressBarValue += 100 / arr.length;
        }
      }, this.timeTableInMs);
      // TODO Fix this Later

      this.timeOutRefArr.push(timeOut);
    }
  }
  setUtterance(uttr: SpeechSynthesisUtterance) {
    uttr.pitch = this.VoiceUttr.pitch;
    uttr.rate = this.VoiceUttr.rate;
    uttr.voice = this.VoiceUttr.voice;
    uttr.volume = this.VoiceUttr.volume;
    return uttr;
  }
  stop() {
    this.stopButton.disabled = true;

    this.timeOutRefArr.forEach((ref) => {
      window.clearTimeout(ref);
    });
    window.clearTimeout(this.timeOutRefFromTest);
    this.testEnded(0);
  }

  testEnded(timeToExecuteInMs: number) {
    if (timeToExecuteInMs === 0) {
      this.EndSettings();
    } else {
      setTimeout(() => {
        this.EndSettings();
      }, this.timeTableInMs);
    }
    this.timeTableInMs = 0;
  }
  EndSettings() {
    const synth = window.speechSynthesis;
    let utrEnd = new SpeechSynthesisUtterance("The test has ended");
    utrEnd = this.setUtterance(utrEnd);
    synth.speak(utrEnd);


    this.isShowingTestContainer = false;
  }

  download() {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.WordsWithIndexes.map((d) => d.word)));
    this.renderer.setAttribute(
      this.downloadButton._elementRef.nativeElement,
      "href",
      dataStr
    );
    this.renderer.setAttribute(
      this.downloadButton._elementRef.nativeElement,
      "download",
      "words.json"
    );
  }
  upload() {
    const dialogRef = this.WordDialog.open(UploadWordsDialogComponent, {
      // width: '250px',
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result != null) {
        result.forEach((w) => {
          this.WordsWithIndexes.push({ word: w, index: this.WordsWithIndexes.length });
          this.table.renderRows();
        });
      }
    });
  }
}
