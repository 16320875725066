import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VoiceService {
  private voiceType = new voiceType(1, null, 1, 1, 3000,1000);

  private voiceUttranceSource = new BehaviorSubject<voiceType>(this.voiceType);
  currentVoice = this.voiceUttranceSource.asObservable();

  constructor () { }

  changeVoice(voiceUttr: voiceType) {
    this.voiceUttranceSource.next(voiceUttr);

  }

}

export class voiceType {
  constructor (
    public volume?: number,
    public voice?: SpeechSynthesisVoice,
    public pitch?: number,
    public rate?: number,
    public pause?: number,
    public countDown?: number
  ) { }
}
